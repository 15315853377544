









































































































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {VTextField} from 'vuetify/lib/components';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab2Results',
  components: {ChemicalLatex, CalculationInput, StembleLatex, STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        massCrude: null,
        massProduct: null,
        appearance: null,
        mpCrudeProduct: null,
        mpRecrystallizedProduct: null,
        pctYield: null,
        language: 'en',
      },
    };
  },
});
